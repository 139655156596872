<template>
  <div>
      MOSTRAR INFORMACION DEL AFILIADO
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>