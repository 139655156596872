var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',_vm._b({staticClass:"font-weight-light overline py-0 elevation-0",attrs:{"padless":_vm.padless}},'v-footer',_vm.localAttrs,false),[_c('v-card',{attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-card-text',{staticClass:"white--textgrey blue-grey lighten-5 elevation-2"},[_vm._v(" Para mas información:   "),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.routeWhatsapp()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}}])},[_c('span',[_vm._v("Whatsapp")])]),_vm._v("     "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.routeFacebook()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-facebook")])],1)]}}])},[_c('span',[_vm._v("Facebook")])]),_vm._v("     "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.routeChatMus()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-text")])],1)]}}])},[_c('span',[_vm._v("Chat Institucional")])]),_vm._v("     ")],1),_c('div',[_vm._v("MUSERPOL "),_c('v-icon',{staticClass:"mdi-flip-h"},[_vm._v("mdi-copyright")]),_vm._v(" "+_vm._s(new Date().getFullYear()))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }